import { render, staticRenderFns } from "./UwzorgonlineSection.vue?vue&type=template&id=bb903bbe"
import script from "./UwzorgonlineSection.vue?vue&type=script&lang=js"
export * from "./UwzorgonlineSection.vue?vue&type=script&lang=js"
import style0 from "./UwzorgonlineSection.vue?vue&type=style&index=0&id=bb903bbe&prod&class=scss&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports