var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"hero-body menu-hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-multiline is-mobile is-variable is-1 is-hidden-tablet"},_vm._l((_vm.menuItems),function(item){return _c('div',{key:item.slug,staticClass:"column",class:{
            'is-12': item.slug === 'spoed',
            'is-6': item.slug !== 'spoed',
          }},[_c('menu-item',{attrs:{"slug":item.slug,"title":item.title,"ispartofprimarymenu":true}})],1)}),0),_c('div',{staticClass:"columns is-multiline is-variable is-2 is-hidden-mobile"},_vm._l((_vm.menuItems),function(item){return _c('div',{key:item.slug,staticClass:"column",class:{
            'is-8': item.slug === 'spoed',
            'is-4': item.slug !== 'spoed',
            'is-hidden-tablet': item.mobileOnly,
          }},[_c('menu-item',{attrs:{"slug":item.slug,"title":item.title,"ispartofprimarymenu":true}})],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }